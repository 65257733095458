/* Generales */
.color-rojo{
    color: #ce242b;
    font-family: 'Lato', sans-serif;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* PAGINA INICIO */
.div_imagen_construccion{
    position: relative;
}

.imagen_construccion{
    text-align: center;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.div_subimagen{
    position: absolute;
    top: 0rem;
    background-color: rgba(0,0,0,0.6);
    z-index: 5;
    width: 100%;
    height: 100%;
}

.hr_construccion{
    margin-top: 20%;
}
.carousel-text{
    color: black;
    display: none;
}
.carousel-indicators li{
    display: none;
}

/*Responsive para inicio*/
@media only screen and (max-width: 499px) {
    .div_subimagen h2{
        font-size: 16px;
        padding: 0px !important;
        margin: 0px !important;
    }

    .hr_construccion{
        margin-top: 10%;
    }

    .div_subimagen h4{
        font-size: 14px;
        padding: 0px !important;
        margin: 0px !important;
    }
}

/* HEADER */
.navbar-text{
    color: #ce242b !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
}
.navbar-text:hover{
    font-weight: 800;
}

/*FOOTER*/

.boton-footer{
    color: #ce242b !important;
    text-decoration: none;
  font-size: xx-large;
}

.boton-footer:hover {
    color: black !important;
    text-decoration: none;
}

.boton-footer-wts{
    color: #ce242b !important;
    text-decoration: none;
  font-size: larger;
}

.boton-footer-wts:hover {
    color: black !important;
    text-decoration: none;
}

.boton-footer-location{
    text-decoration: none;
  font-size: larger;
}

/*NOSOTROS*/
.fade-appear{
opacity: 0;
}
.fade-appear.fade-appear-active{
    opacity: 1;
    transition: opacity 300ms linear;
}