.background1{
    background-image: url('img/servicios1.png');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.background2{
    background-image: url('img/servicios2.png');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.background3{
    background-image: url('img/servicios3.PNG');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.background4{
    background-image: url('img/servicios4.png');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.background5{
    background-image: url('img/servicios5.JPG');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.background6{
    background-image: url('img/servicios6.png');
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%) blur(3px);
    position: relative;
}

.serviciosText{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 100;
}